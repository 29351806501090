module.exports = {
    menu: [ 
        {
            title: "inicio",
            href: "banner"
        },
        {
            title:"Actividades",
            href:"Activities"
        },
        {
            title:"Alojamiento",
            href:"Accommodation"
        },
        {
            title:"Restaurante",
            href:"Restaurant"
        },
        {
            title:"Galería", 
            href:"Gallery", 
        }
    ], 
    wrappersTransparents: {
        items: [
            {
                title: "Descubre las cosas que puedes encontrar ",
                id: "wrappers-one",
                p: "Martin pescador 5 especies diferentes Pava Amazónica o chenchena.",
                style: "",
            }, 
            {
                title: "Descubre las cosas que puedes encontrar ",
                id: "wrappers-two",
                p: "Tucanes, Patos cormorán y pato aguja.",
                style: "",
            }, 
            {
                title: "Descubre las cosas que puedes encontrar ",
                id: "wrappers-three",
                p: "Garza morena, Garza vaca, Colibrís, El jamuco el ave más grande de la zona.",
                style: "",
            }, 
            {
                title: "Descubre las cosas que puedes encontrar ",
                id: "wrappers-three",
                p: "Garza morena, Garza vaca, Colibrís, El jamuco el ave más grande de la zonaGarza morena, Garza vaca, Colibrís, El jamuco el ave más grande de la zona.",
                style: "",
            }
        ]
    },
    wrapper: {
        banner: {
            title: "Naturaleza, turismo y logistica",
            id: "banner",
            description: "Vive una verdadera experiencia en la naturaleza",
            style: ""
        },
        services: {
            title: "¿Qué deseas hacer?",
            id: "services",
            p: "soy diseñador web profesional y desarrollador web",
            style: "",
            items: [
                {
                    name:"senderismo vistas guiadas",
                    icon: 'IconExcursionismo',
                    float: "right",
                    description:"Lorem ipsum dolor sit amet, etiam lorem adipiscing elit. Cras turpis ante, nullam sit amet turpis non, sollicitudin posuere urna. Mauris id tellus arcu. Nunc vehicula id nulla dignissim dapibus. Nullam ultrices, neque et faucibus viverra, ex nulla cursus."
                },
                {
                    name:"ALOJAMIENTO GRUPAL",
                    icon: 'IconCabin',
                    float: "left",
                    description:"Alojamiento en cabanas ecologicas  contruidas en madera remodelada de bosque amazonico con una gran biodiversidad, un lugar perfecto para tener un contacto directo con la naturaleza"
                }, 
                {
                    name:"Sendero acuatico",
                    icon: 'IconCanoe',
                    float: "right",
                    description: "Desde el alojamiento podemo aser Kayak o dar un paseo en bote y disfrutar del sendero observacion de aves, primates, flora y fauna, ademas nadas en la laguna."
                },
            ]
        }, 
        housing: {
            title: "ALOJAMIENTOS",
            id: "alojamientos",
            p: "Alojamientos rurales en Cabañas Ecológicas construidas en maderas nativas,",
            style: "",
            items: [
                {
                    name: "CABAÑAS",
                    icon:"cabanasMin",
                    float: "right",
                    description:"Contamos con (3) Cabañas , Cada una con (1) una Cama Doble y (1) una Sencilla, Mosquiteros, Energía solar, Baño con Ducha Privada y Balcón con Amaca. Capacidad máxima (3) Tres Personas por cabaña"
                }, 
                {
                    name: "CAMPAMENTO",
                    icon:"campamento",
                    float: "left",
                    description:"Acomodaciones con (3) Tres Baños y (4) Cuatro Duchas , Mosquiteros, Energía solar , ideal para grupos grandes de Viajeros o Estudiantes. Acomodación múltiple en camarotes. Próximamente con divisiones de espacios Capacidad máxima (20) Veinte Personas en el Campamento"
                }

            ]
        }, 
        trails: {
            title: "SENDEROS",
            id: "senderos",
            p: "SENDEROS ruraleS CON LA MEJOR EXPERIENCIAS",
            style: "",
            items: [
                {
                    name: "fluvial Caño Negro a Laguna Negra.",
                    icon:"fluvial",
                    float: "right",
                    description:"Este sendero se puede hacer en canoa con motor o en kayak un sendero rodeado de naturaleza propia de la Amazonia y una gran biodiversidad que convierte este sendero ideal para la admiración de la naturaleza ideal para la observación de aves y primates."
                }, 
                {
                    name: "Río Guaviare",
                    icon:"guaviare",
                    float: "left",
                    description:"Acomodaciones con (3) Tres Baños y (4) Cuatro Duchas , Mosquiteros, Energía solar , ideal para grupos grandes de Viajeros o Estudiantes. Acomodación múltiple en camarotes. Próximamente con divisiones de espacios Capacidad máxima (20) Veinte Personas en el Campamento"
                }

            ]
        }, 
        restaurant: {
            title: "restaurante",
            id: "alojamientos",
            p: "Alojamientos rurales en Cabañas Ecológicas construidas en maderas nativas,",
            style: "",
            items: [
                {
                    name: "Horario: 7:00 am a 9:00 pm",
                    icon:"cabin.jpg",
                    float: "left",
                    description:"Nuestros platos principalmente son platos campesinos preparados en fogón de leña utilizando productos del campo, Frutos de nuestra region, donde sobresale…"
                }
            ]
        }, 
        contact: {
            title: "contacto",
            id: "contacto",
            p: "Hablemos de su proyecto ",
        }
    }
}