import React from 'react';
import { menu } from "../config/data";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import logo from "../assets/images/logo.svg";

export const Header = () => {
    return (
        <header>
            <div className="container-fluid">
                <div className="navbar-header">
                <img  src={logo} className="logo"  alt='logo' />
                <nav>
                    <ul>
                        {
                            menu.map(({title, href})=> {
                                return <li key={title}><AnchorLink key={title} href={`#${href}`}>{title}</AnchorLink></li>
                            })
                        }
                    </ul>
                </nav>
                </div>
            </div>  
	    </header>
    );
};

