

import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { wrapper } from  '../../config/data';

const useHousingImg = () => {
    const data =useStaticQuery(graphql`
    {
      restaurant: file(relativePath: {eq: "housing/cabanas-min.jpeg"}) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)
    return data
  }

const Restaurant  = () => {
    const { id, title, items, p} = wrapper.restaurant;
    const {restaurant } = useHousingImg();
    return (
        <section id={id} className="wrapper white">
            <div className="container">
                <div className="row">
                    <header>
                        <h2>{`${title}`}</h2>
                        <p>{p}</p>
                    </header>
                </div>
                {items.map((row, i) => (
                    <div className="row" key={`${row}-${i} `}>
                    <article className="col-12 col-12-small services">
                        <img className="__image"  src={restaurant.childImageSharp.fixed.src}  alt={`icon-${row.icon}` } /> 
                        <div className='content'>
                            <header>
                                <h2 className={`aling-${row.float}`}>{row.name}</h2>
                            </header>
                            <p>{row.description}</p>
                        </div>
                    </article>
                </div>
                ))}
            </div>
        </section>
    );
};

export default Restaurant
