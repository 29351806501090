

import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { wrapper } from  '../../config/data';
import {  rowsItems }  from '../../utils';

const useHousingImg = () => {
    const data =useStaticQuery(graphql`
    {
      cabanasMin: file(relativePath: {eq: "housing/cabanas-min.jpeg"}) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
      campamento: file(relativePath: {eq: "housing/campamento-min.jpeg"}) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)
    return data
  }

const Housing  = () => {
    const { id, title, items, p} = wrapper.housing;
    const rows  = rowsItems(items);
    const data = useHousingImg();
    return (
        <section id={id} className="wrapper white">
            <div className="container">
                <div className="row">
                    <header>
                        <h2>{`${title}`}</h2>
                        <p>{p}</p>
                    </header>
                </div>
                {
                    rows.map((row, i) => {
                        let grid =  12 / row.length  
                        return <div key={i} className="row">
                        {row.map(({name, icon, description}, j) => {
                            return <div key={j} className={`feature col-${grid}-xlarge `} >
                                <div className="box">
                                    <img
                                        className="box__img"
                                        src={data[icon].childImageSharp.fixed.src}
                                        alt={name}
                                    />
                                    <h2>{name}</h2>
                                    <p>{description}</p>
                                </div>
                            </div> 
                        })}
                        </div>
                    })
                } 
            </div>
        </section>
    );
};

export default Housing
