

import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { wrapper } from  '../../config/data';


const useTrailsImg = () => {
    const data =useStaticQuery(graphql`
    {
        fluvial: file(relativePath: {eq: "trails/fluvial.jpg"}) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
      guaviare: file(relativePath: {eq: "trails/rio-guaviare.jpg"}) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)
    return data
  }

 const Trails = () => {
    const { id, title, items } = wrapper.trails;
    const data = useTrailsImg();

    return (
        <section id={id} className="wrapper green">
            <div className="container">
                <div className="row">
                    <header>
                        <h2>{`${title}`}</h2>
                    </header>
                </div>
                {items.map((row, i) => (
                    <div className="row" key={`${row}-${i} `}>
                    <article className="col-12 col-12-mobile services">
                        {row.float === 'left' && <img className="image featured" src={data[row.icon].childImageSharp.fixed.src} alt={`icon-${row.icon}` } /> }
                        <div className='content'>
                            <header>
                                <h2 className={`aling-${row.float}`}>{row.name}</h2>
                            </header>
                            <p>{row.description}</p>
                        </div>
                        {row.float === 'right' && <img className="image featured" src={data[row.icon].childImageSharp.fixed.src} alt={`icon-${row.icon}` } /> }
                    </article>
                </div>
                ))}
            </div>
        </section>
    );
};

export default Trails