module.exports = {
    defaultTitle: 'Naturlog Playa Guio' ,
    logo: 'logo-90f29cab542e2499f33ae0fc3b2a7add.svg',
    author: 'miigangls',
    url: 'https://naturlogplayaguio.com',
    legalName: 'naturlogplayaguio',
    defaultDescription: 'Fuimos familia guardabosques que decimos voluntariamente dejar la coca por el ecoturismo onde los locales somos los anfitriones',
    socialLinks: {
      twitter: 'http://www.twitter.com/naturlogplayaguio',
      facebook: 'http://www.facebook.com/naturlogplayaguio',
      instagram: 'https://instagram.com/naturlogplayaguio',
    },
    social: {
      facebook: 'naturlogplayaguio',
      twitter: '@naturlogplayaguio',
    }
  };
  