

import React from 'react';
import { wrapper } from  '../../config/data';
import excursionismo from '../../assets/images/svg/excursionismo.svg';
import canoe from '../../assets/images/svg/canoe.svg';
import cabin from '../../assets/images/svg/cabin.svg';


const ObjectIcons = {
    IconExcursionismo: excursionismo, 
    IconCanoe: canoe, 
    IconCabin: cabin
}

export const Services = () => {
    const { id, title, items } = wrapper.services;
    return (
        <section id={id} className="wrapper green">
            <div className="container">
                <div className="row">
                    <header>
                        <h2>{`${title}`}</h2>
                    </header>
                </div>
                {items.map((row, i) => (
                    <div className="row" key={`${row}-${i}`}>
                    <article className="col-12 col-12-small services">
                        {row.float === 'left' && <img className="svg" src={ObjectIcons[row.icon]} alt={`icon-${row.icon}` } /> }
                        <div className='content'>
                            <header>
                                <h2 className={`aling-${row.float}`}>{row.name}</h2>
                            </header>
                            <p>{row.description}</p>
                        </div>
                        {row.float === 'right' && <img className="svg" src={ObjectIcons[row.icon]} alt={`icon-${row.icon}` } /> }
                    </article>
                </div>
                ))}
            </div>
        </section>
    );
};