import React from 'react';
import { wrapper } from "../config/data";

const Banner = () => {
    const {   banner } = wrapper;
    return (
        <section id="banner">
            <div className="banner-content">
                <h2>{banner.title}</h2>
                <p>{banner.description}</p>
            </div>
        </section>
    );
};

export default Banner