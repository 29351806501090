import * as React from "react";
import '../assets/scss/main.scss';
import {Layout, SEO, Header, Services,   } from '../components';
import Wallpaper from '../components/wallpaper';
import Housing from '../components/wrapper/housing';
import Restaurant from '../components/wrapper/restaurant';
import Trails from '../components/wrapper/trails';
import Banner from '../components/banner';
export const Head = () => <title>Home Page</title>

const IndexPage= () => {
  return (
    <Layout>
      <SEO />
      <Header />
      <Banner />
      <Services />
      <Wallpaper number={0} />
      <Housing />
      <Wallpaper number={1} />
      <Trails />
      <Wallpaper number={2} />
      <Restaurant />
      <Wallpaper number={3} />
    </Layout>
  )
}

export default IndexPage;
