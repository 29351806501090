

import React from 'react';
import { wrappersTransparents } from '../config/data';

const Wallpaper = ({number}) => {
    const { id, title, p } = wrappersTransparents.items[number];
    return (
        <section id={id} className="wrapper transparent">
            <div className="container">
                <div className="row">
                    <div className='content'>
                        <header>
                            <p>{title}</p>
                            <h2>{p}</h2>
                        </header>
                    </div>
                </div>
            </div>
        </section>
    );
} ;


export default Wallpaper